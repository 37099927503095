export default function widgetCode(siteId, widgetId, widget) {
  const { type } = widget
  if (type === 'position_fixed') {
    return `<div id='phoenix${widgetId}'></div>
<script type="text/javascript" src="//phoenix-widget.com/static/js/widget_fixed.js"><${''}/script>
<script async type="text/javascript">
new PhoenixWidget({
siteId: "${siteId}",
widgetId: "${widgetId}",
});
<${''}/script>`
  }
  if (type === 'builder') {
    return `<script src="//phoenix-widget.com/static/js/builder/w.js?site_id=${siteId}&widget_id=${widgetId}"><${''}/script>`
    // return `<div id='phoenix${widgetId}'></div>
    // <script src="//phoenix-widget.com/static/js/builder/widget.js" ><${''}/script>
    // <script async type="text/javascript">
    // new PhoenixWidgetB({
    // siteId: "${siteId}",
    // widgetId: "${widgetId}",
    // });
    // <${''}/script>`
  }
  return `<div id='phoenix${widgetId}'></div>
<script type="text/javascript" src="//phoenix-widget.com/static/js/jsonp_v3.js"><${''}/script>
<script async type="text/javascript">
new PhoenixWidget({
siteId: "${siteId}",
widgetId: "${widgetId}",
});
<${''}/script>`
}
