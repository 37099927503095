<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <SiteToolbar
        v-if="Object.keys(site).length !== 0"
        :site="site"
        closing
      />
      <hr>
      <div
        v-if="widgetLoading"
        class="text-center"
      >
        <b-spinner label="Loading..." />
      </div>
      <widgets-table
        v-else
        :rows="widgets"
        @refresh="getSiteWidgets()"
      />
    </b-card>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import SiteToolbar from '@/views/dashboard/sites/SiteToolbar.vue'
import {
  BCard,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import WidgetsTable from './WidgetsTable.vue'

export default {
  components: {
    BCard,
    BSpinner,
    Breadcrumbs,
    UserToolbar,
    WidgetsTable,
    SiteToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      authUserData: getUserData(),
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      widgetLoading: true,
      widgets: [],
      site: [],
      account: {},
    }
  },
  created() {
    this.getUserData()
    this.getSiteWidgets()
  },
  methods: {
    async getSiteWidgets() {
      this.widgets = []
      const responseData = await useJwt.getSiteWidgets({ site_id: this.$route.params.siteId })
      this.widgets = responseData.data.widgets || []
      this.widgetLoading = false
    },
    async getSiteData() {
      const responseData = await useJwt.getSite(this.$route.params.siteId)
      this.site = responseData.data.site || []
      this.setBreabcrumbs()
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.getSiteData()
    },
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('page.menu.users'),
          to: '/users',
        },
        {
          text: this.pageTitle,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: this.$t('page.user.sites'),
          to: `/users/${this.$route.params.userId}/sites`,
        },
        {
          text: this.site.title,
          to: `/users/${this.$route.params.userId}/sites/${this.$route.params.siteId}`,
        },
        {
          text: this.$t('page.features.widgets'),
          active: true,
        },
      ]
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
