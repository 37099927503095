<template>
  <div>
    <site-parser
      :data="setParser"
      :xl="12"
      :sm="12"
    />
    <div class="head-card">
      <div class="head-card-top">
        <h4>{{ site.title }}</h4>
        <feather-icon
          v-if="closing"
          v-b-tooltip.hover.top="$t('Close')"
          icon="XIcon"
          size="18"
          class="toggle-close cursor-pointer"
          @click="$router.go(-1)"
        />
      </div>
      <hr>
      <div class="head-card-bottom">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ name: 'dashboard-site' }"
          exact
        >
          <feather-icon
            icon="GlobeIcon"
            size="16"
          />
          {{ $t('page.overview') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ name: 'dashboard-site-widgets' }"
        >
          <feather-icon
            icon="GridIcon"
            size="16"
          />
          {{ $t('page.features.widgets') }}
        </b-button>
        <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ name: 'dashboard-site-stats'}"
        >
          <feather-icon
            icon="BarChart2Icon"
            size="16"
          />
          {{ $t('Statistics') }}
        </b-button> -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ name: 'dashboard-site-promotions' }"
        >
          <feather-icon
            icon="TagIcon"
            size="16"
          />
          {{ $t('Promotions') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ name: 'dashboard-site-clicks' }"
        >
          <feather-icon
            icon="MousePointerIcon"
            size="16"
          />
          {{ $t('Clicks sources') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ name: 'dashboard-site-toparticles' }"
        >
          <feather-icon
            icon="ActivityIcon"
            size="16"
          />
          {{ $t('TOP articles') }}
        </b-button>
        <b-button
          v-if="site.articles !== undefined && site.articles.count !== 0"
          v-b-modal.modal-center
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
        >
          <feather-icon
            icon="CpuIcon"
            size="16"
          />
          {{ $t('Parser') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ name: 'dashboard-sites-edit' }"
        >
          <feather-icon
            icon="SettingsIcon"
            size="16"
          />
          {{ $t('Settings') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import getFormattedDate from '@/utils/date-formatter'
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import SiteParser from './SiteParser.vue'

export default {
  name: 'SiteToolbar',
  components: {
    BButton,
    SiteParser,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
    closing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getFormattedDate,
    }
  },
  computed: {
    setParser() {
      const parser = []
      parser.push(
        {
          icon: 'PlayIcon',
          color: 'light-danger',
          title: this.site.articles !== undefined && this.site.articles.count
            ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(this.site.articles.count)
            : 0,
          subtitle: 'Processed',
          customClass: 'mb-2',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-info',
          title: this.site.articles !== undefined && this.site.articles.date
            ? this.getFormattedDate(new Date(this.site.articles.date), true)
            : 'Unknown',
          subtitle: 'Updated',
          customClass: 'mb-2',
        },
      )
      return parser
    },
  },
}
</script>
