<template>
  <b-modal
    id="modal-center"
    ref="my-modal"
    centered
  >
    <template #modal-header>
      <h5 class="modal-title">
        {{ $t('page.features.articles') }}
      </h5>
      <b-card-text class="font-small-2 mr-25 mb-0">
        <b-badge variant="info">
          {{ $t('Parser') }}
        </b-badge>
      </b-card-text>
    </template>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-b-tooltip.hover.top="$t('Close')"
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="primary"
          class="btn-icon float-right"
          @click="hideModal"
        >
          {{ $t('Close') }}
        </b-button>
        <div class="clear-parser float-left">
          <b-button
            v-b-tooltip.hover.top="$t('sites.clear-parser')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="danger"
            class="btn-icon"
            @click="clearParser"
          >
            <feather-icon
              icon="Trash2Icon"
              size="16"
            />
          </b-button>
        </div>
      </div>
    </template>
    <b-card-body class="statistics-body">
      <div
        v-if="loading"
        class="text-center"
      >
        <b-spinner label="Loading..." />
      </div>
      <div v-else>
        <b-row v-if="data.length !== 0">
          <b-col
            v-for="item in data"
            :key="item.icon"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle === "Processed" ? $t('page.features.complete') : item.subtitle === 'Updated' ? $t('page.features.lastupdate') : item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
        <div v-else>
          <b-alert
            variant="secondary"
            class="mt-2"
            show
          >
            <div class="alert-body text-center">
              <span>{{ $t('No data for this period') }}</span>
            </div>
          </b-alert>
        </div>
      </div>
    </b-card-body>
  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BAlert,
  BMediaBody,
  BBadge,
  BSpinner,
  BModal,
  VBModal,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BAlert,
    BMediaAside,
    BMediaBody,
    BBadge,
    BSpinner,
    BModal,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  created() {
    this.complate()
  },
  methods: {
    complate() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    clearParser() {
      this.$swal({
        title: '',
        text: this.$t('sites.clear-urls'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deactivation!',
            text: 'Successfully deactivated',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Cleaning cancelled',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>
<style scoped>
.card-statistics {
  height: 100%;
}
.card-statistics .mb-2:last-child {
  margin-bottom: 0 !important;
}

.statistics-body {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media (max-width: 768px) {
  .card-statistics {
    height: auto;
  }

  .statistics-body {
    padding-bottom: 2.8rem !important;
  }
}
</style>
